<template>
  <div></div>
</template>

<script>
export default {
  name: "Dashboard",

  computed: {}
};
</script>

<style scoped></style>
