<template>
  <div>
    <div class="text-overline">Mitglieder</div>
    <v-row class="mt-4">
      <v-col
        cols="12"
        lg="3"
        md="4"
        v-for="user in team.usersShow"
        :key="user.id"
      >
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-avatar color="primary" class="white--text">
                  <span v-if="!user.image_url">{{ user.initials }}</span>
                  <img v-else :src="user.image_url" alt="Profil" />
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ user.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ user.email }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ user.phone1 }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <div class="mt-10">
      <div class="text-overline">Anfragen zur gemeinsamen Bearbeitung</div>
      <v-row class="mt-4">
        <v-col
          cols="12"
          md="3"
          v-for="inquiry in team.assignedInquiries"
          :key="inquiry.id"
        >
          <util-inquiry-card :inquiryProp="inquiry" />
        </v-col>
      </v-row>
    </div>
    <div class="mt-10">
      <div class="text-overline">Mit Team geteilte Objekte</div>
      <v-row class="mt-4">
        <v-col
          cols="12"
          md="3"
          v-for="share in team.sharedWith"
          :key="share.id"
        >
          <util-inquiry-card
            :inquiryProp="share.shareable"
            v-if="share.shareable_type === 'App\\Models\\Inquiry'"
            :isShare="true"
            :share="share"
          />
          <util-inquiry-item-card
            :inquiryItemProp="share.shareable"
            v-if="share.shareable_type === 'App\\Models\\InquiryItem'"
            :isShare="true"
            :share="share"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    isValid: false,
    team: []
  }),
  computed: {
    userDetails() {
      return this.$store.getters["state/userDetails"];
    }
  },
  created() {
    this.showTeam();
  },
  methods: {
    showTeam() {
      this.axios
        .get("/teams/" + this.$route.params.id)
        .then(res => {
          this.team = res.data.team;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error("Ein Fehler ist aufgetreten.");
        });
    }
  }
};
</script>

<style scoped></style>
