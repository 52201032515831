<template>
  <div>
    <inquiries-wizard-wrapper :is-template="true" />
    <v-row justify="center" class="mt-8">
      <v-col cols="12" xl="6" lg="8">
        <router-view />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {},
  data: () => ({}),
  computed: {
    inquiry() {
      return this.$store.getters["currentInquiry/currentInquiry"];
    }
  },
  watch: {},
  created() {
    this.showCurrentInquiry(this.$route.params.id).then(() => {
      //
    });
  },
  methods: {
    ...mapActions({
      showCurrentInquiry: "currentInquiry/showInquiry",
      indexAvailableDocuments: "currentInquiry/indexAvailableDocuments"
    })
  }
};
</script>

<style scoped></style>
