<template>
  <div>
    <div
      class="mb-10"
      v-if="$hasPermissions('consultant permission') && !$route.query.type"
    >
      <v-btn-toggle>
        <v-btn text height="38px" :to="{ name: 'InquiriesColumn' }">
          Spaltenansicht
        </v-btn>
        <v-btn text height="38px" :to="{ name: 'InquiriesList' }">
          Listenansicht
        </v-btn>
      </v-btn-toggle>
      <v-btn
        height="38px"
        depressed
        class="ml-4"
        :to="{ name: 'InquiriesForm' }"
      >
        Neu anlegen
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </div>
    <div class="text-overline">Ihre Anfragen</div>
    <v-row>
      <v-col cols="12" xl="7" lg="9">
        <v-row>
          <v-col cols md="6">
            <v-text-field
              solo
              solo-inverted
              flat
              placeholder="Suchen"
              prepend-inner-icon="mdi-magnify"
              dense
              hide-details
              v-model="search"
            />
          </v-col>
          <v-col cols md="3" v-if="$hasPermissions('consultant permission')">
            <v-select
              solo
              solo-inverted
              flat
              placeholder="Sortieren"
              prepend-inner-icon="mdi-magnify"
              dense
              hide-details
              :items="keys"
              v-model="sortBy"
            />
          </v-col>
          <v-col cols md="3" v-if="$hasPermissions('consultant permission')">
            <v-btn-toggle v-model="sortDesc" mandatory style="width: 50%">
              <v-btn
                block
                height="38px"
                depressed
                color="grey lighten-3"
                :value="false"
              >
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn
                block
                height="38px"
                depressed
                color="grey lighten-3"
                :value="true"
              >
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <v-data-iterator
          :items="items"
          :search="search"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          class="mt-4"
        >
          <template v-slot:default="props">
            <v-card
              v-for="inquiry in props.items"
              :key="inquiry.id"
              class="mt-4"
            >
              <v-list-item>
                <v-list-item-avatar
                  v-if="$hasPermissions('consultant permission')"
                >
                  <v-tooltip top v-if="inquiry.project_state === 'open'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">
                        mdi-radiobox-marked
                      </v-icon>
                    </template>
                    <span>Offen</span>
                  </v-tooltip>

                  <v-tooltip top v-if="inquiry.project_state === 'in_work'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">
                        mdi-briefcase-outline
                      </v-icon>
                    </template>
                    <span>In Arbeit</span>
                  </v-tooltip>

                  <v-tooltip top v-if="inquiry.project_state === 'finished'">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">
                        mdi-checkbox-marked-circle-outline
                      </v-icon>
                    </template>
                    <span>Fertig</span>
                  </v-tooltip>
                </v-list-item-avatar>
                <v-list-item-avatar>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar
                        v-if="inquiry.assignable"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        class="white--text"
                        size="40"
                      >
                        <span v-if="!inquiry.assignable.image_url">{{
                          inquiry.assignable.initials
                        }}</span>
                        <img
                          v-else
                          :src="inquiry.assignable.image_url"
                          alt="Profilbild"
                        />
                      </v-avatar>
                    </template>
                    <span>{{ inquiry.assignable.name }}</span>
                  </v-tooltip>
                </v-list-item-avatar>
                <v-list-item-content class="ml-6 mr-6">
                  <v-list-item-subtitle
                    v-if="
                      inquiry.customer &&
                        $hasPermissions('consultant permission')
                    "
                    class="mb-2"
                  >
                    <v-chip label small class="mt-1">
                      {{ inquiry.customer.name }}
                      <v-tooltip top v-if="inquiry.customer.customerType">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-avatar
                              :color="inquiry.customer.customerType.color"
                              size="10"
                            ></v-avatar>
                          </v-btn>
                        </template>
                        <span>{{ inquiry.customer.customerType.name }}</span>
                      </v-tooltip>
                    </v-chip>
                  </v-list-item-subtitle>
                  <v-list-item-title
                    class="font-weight-bold"
                    v-if="inquiry.product"
                  >
                    {{ inquiry.product.name }}
                  </v-list-item-title>
                  <v-list-item-title v-if="inquiry.name">
                    {{ inquiry.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ inquiry.description }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="mt-1">
                    Letzte Aktualisierung:
                    {{ inquiry.updated_at | moment("DD.MM.YYYY HH:mm") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip outlined color="success">5/5</v-chip>
                </v-list-item-action>
                <v-list-item-action>
                  <v-btn
                    icon
                    :to="
                      $route.query.type === 'draft'
                        ? { name: 'InquiriesEdit', params: { id: inquiry.id } }
                        : { name: 'InquiriesShow', params: { id: inquiry.id } }
                    "
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </template>
        </v-data-iterator>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    search: "",
    loading: false,
    isValid: false,
    assignedInquiries: [],
    assignedInquiries_draft: [],
    sharedWithUser: [],
    sharedWithTeam: [],
    sortBy: "updated_at",
    keys: [
      { value: "updated_at", text: "Zuletzt aktualisiert" },
      { value: "name", text: "Name" },
      { value: "customer.name", text: "Kunde" }
    ],
    sortDesc: true
  }),
  computed: {
    userDetails() {
      return this.$store.getters["state/userDetails"];
    },
    items() {
      switch (this.$route.query.type) {
        case "sharedWithUser":
          return this.sharedWithUser;
        case "sharedWithTeam":
          return this.sharedWithTeam;
        case "draft":
          return this.assignedInquiries_draft;
      }
      return this.assignedInquiries;
    }
  },
  created() {
    this.showDashboardInquiries();
  },
  methods: {
    showDashboardInquiries() {
      this.axios
        .get("/dashboard/inquiries")
        .then(res => {
          this.assignedInquiries = res.data.assignedInquiries;
          this.assignedInquiries_draft = res.data.assignedInquiries_draft;
          this.sharedWithUser = res.data.sharedWithUser;
          this.sharedWithTeam = res.data.sharedWithTeam;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error("Ein Fehler ist aufgetreten.");
        });
    }
  }
};
</script>

<style scoped></style>
