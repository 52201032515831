<template>
  <v-row>
    <v-col cols="12" xl="6" lg="8">
      <v-form
        lazy-validation
        ref="form"
        v-model="isValid"
        @submit.prevent="submit()"
      >
        <div class="text-overline">
          Allgemein
        </div>

        <v-text-field
          v-model="document_type.name"
          filled
          label="Name"
          :rules="[rules.required, rules.short_text]"
          required
          :counter="280"
        />

        <v-textarea
          v-model="document_type.description"
          filled
          label="Beschreibung"
          :rules="[rules.long_text]"
          :counter="6000"
        />

        <div class="d-flex justify-end mt-6">
          <v-btn text :to="{ name: 'SettingsDocumentTypes' }" exact
            >Abbrechen</v-btn
          >
          <v-btn
            class="ml-2"
            color="primary"
            type="submit"
            :disabled="!isValid"
            :loading="loading"
          >
            Speichern
          </v-btn>
        </div>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    isValid: false,
    document_type: {
      name: "",
      description: ""
    }
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {
    $route(to) {
      if (to.name === "SettingsDocumentTypesEdit" && to.params.id) {
        this.showDocumentType();
      }
    }
  },
  created() {
    if (this.$route.params.id) {
      this.showDocumentType();
    }
  },
  methods: {
    submit() {
      const url = "/document-types";
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.$route.params.id) {
          this.axios
            .patch(url + "/" + this.$route.params.id, {
              document_type: this.document_type
            })
            .then(() => {
              this.successMethod();
            })
            .catch(e => {
              this.errorMethod(e);
            });
        } else {
          this.axios
            .post(url, {
              document_type: this.document_type
            })
            .then(() => {
              this.successMethod();
            })
            .catch(e => {
              this.errorMethod(e);
            });
        }
      }
    },
    showDocumentType() {
      this.axios
        .get("/document-types/" + this.$route.params.id)
        .then(r => {
          this.document_type = { ...r.data.document_type };
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Abrufen des Dokumententyps ist ein Fehler aufgetreten."
          );
        });
    },
    successMethod() {
      this.$router.push({
        name: "SettingsDocumentTypes"
      });
      this.$snotify.success("Der Dokumententyp wurde erfolgreich gespeichert.");
    },
    errorMethod(e) {
      console.log(e);
      this.$snotify.error(
        "Beim Speichern des Dokumententyps ist ein Fehler aufgetreten."
      );
    }
  }
};
</script>

<style scoped></style>
