<template>
  <div>
    <div v-if="teams.length < 1" class="body-1">
      Sie sind noch keinem Team zugeordnet.
    </div>
    <v-row>
      <v-col cols="12" md="3" v-for="team in teams" :key="team.id">
        <router-link
          class="custom-link"
          :to="{ name: 'TeamsShow', params: { id: team.id } }"
        >
          <v-card height="100%">
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="heading">{{
                    team.name
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    team.description
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </router-link>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    isValid: false,
    teams: []
  }),
  computed: {
    userDetails() {
      return this.$store.getters["state/userDetails"];
    }
  },
  created() {
    this.indexUserTeams();
  },
  methods: {
    indexUserTeams() {
      this.axios
        .get("/users/teams/" + this.userDetails.id)
        .then(res => {
          this.teams = res.data.teams;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error("Ein Fehler ist aufgetreten.");
        });
    }
  }
};
</script>

<style scoped></style>
