<template>
  <div>
    <v-row align="center">
      <v-col cols="12" lg="4" md="6">
        <v-text-field
          solo
          solo-inverted
          flat
          placeholder="Suchen"
          prepend-inner-icon="mdi-magnify"
          dense
          hide-details
          v-model="search"
        />
      </v-col>
      <v-col cols="12" xl="2" lg="3">
        <v-btn
          depressed
          block
          height="38px"
          :to="{ name: 'SettingsDocumentTypesCreate' }"
        >
          Neuer Dokumententyp
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="8" lg="10">
        <v-card>
          <v-tabs color="black" background-color="transparent">
            <v-tab>Aktiv</v-tab>
            <v-tab>Archiviert</v-tab>

            <v-tab-item>
              <v-card flat>
                <settings-data-table
                  :items="document_types"
                  :headers="headers"
                  :search="search"
                  editRoute="SettingsDocumentTypesEdit"
                />
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <settings-data-table
                  :items="document_types_archived"
                  :headers="headers"
                  :search="search"
                  editRoute="SettingsDocumentTypesEdit"
                />
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    document_types: [],
    document_types_archived: [],
    search: "",
    headers: [
      { text: "Name", value: "name" },
      { text: "Beschreibung", value: "description" },
      { text: "Anzahl Dokumente", value: "documents_count" },
      { value: "action", sortable: false }
    ]
  }),
  computed: {},
  created() {
    this.indexDocumentTypes();
  },
  methods: {
    indexDocumentTypes() {
      this.axios
        .get("/document-types")
        .then(res => {
          this.document_types = res.data.document_types;
          this.document_types_archived = res.data.document_types_archived;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error("Ein Fehler ist aufgetreten.");
        });
    }
  }
};
</script>

<style scoped></style>
