<template>
  <v-row>
    <v-col cols="12" xl="6" lg="8">
      <v-form
        lazy-validation
        ref="form"
        v-model="isValid"
        @submit.prevent="submit()"
      >
        <div class="text-overline">
          Allgemein
        </div>

        <v-text-field
          v-model="product.name"
          filled
          label="Name"
          :rules="[rules.required, rules.short_text]"
          required
          :counter="280"
        />

        <v-textarea
          v-model="product.description"
          filled
          label="Beschreibung"
          :rules="[rules.long_text]"
          :counter="6000"
        />

        <v-select
          v-model="product.customer_type_id"
          filled
          label="Kundentyp"
          :items="customerTypes"
          item-value="id"
          item-text="name"
        />

        <div class="text-overline mt-4">
          Logo
        </div>
        <img
          v-if="product.logo_url"
          style="max-height: 100px; max-width: 300px;"
          :src="product.logo_url"
          class="mt-2 mb-2"
        />
        <util-file-drop :fileProp="file" @pass="file = $event" />

        <div class="d-flex justify-end mt-6">
          <v-btn text :to="{ name: 'SettingsProducts' }">Abbrechen</v-btn>
          <v-btn
            class="ml-2"
            color="primary"
            type="submit"
            :disabled="!isValid"
            :loading="loading"
          >
            <template v-slot:loader v-if="file">
              <span>{{ uploadPercentage }} %</span>
            </template>
            Speichern
          </v-btn>
        </div>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    isValid: false,
    product: {
      name: "",
      description: "",
      customer_type_id: ""
    },
    customerTypes: [],
    file: ""
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    }
  },
  watch: {
    $route(to) {
      if (to.name === "SettingsProductsEdit" && to.params.id) {
        this.showProduct();
        this.indexCustomerTypes();
      }
    }
  },
  created() {
    this.indexCustomerTypes();
    if (this.$route.params.id) {
      this.showProduct();
    }
  },
  methods: {
    indexCustomerTypes() {
      this.axios
        .get("/customer-types")
        .then(r => {
          this.customerTypes = r.data.customerTypes;
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Abrufen der Kundentypen ist ein Fehler aufgetreten."
          );
        });
    },
    submit() {
      const url = "/products";
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.$route.params.id) {
          this.axios
            .patch(url + "/" + this.$route.params.id, {
              product: this.product
            })
            .then(() => {
              this.storeLogo(this.$route.params.id);
              this.successMethod();
            })
            .catch(e => {
              this.errorMethod(e);
            });
        } else {
          this.axios
            .post(url, {
              product: this.product
            })
            .then(r => {
              this.storeLogo(r.data.id);
              this.successMethod();
            })
            .catch(e => {
              this.errorMethod(e);
            });
        }
      }
    },
    showProduct() {
      this.axios
        .get("/products/" + this.$route.params.id)
        .then(r => {
          this.product = { ...r.data.product };
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Abrufen des Produkts ist ein Fehler aufgetreten."
          );
        });
    },
    storeLogo(id) {
      if (this.file) {
        let formData = new FormData();
        formData.append("file", this.file);
        this.axios
          .post("products/logo/" + id, formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            },
            onUploadProgress: function(progressEvent) {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }.bind(this)
          })
          .then(() => {})
          .catch(e => {
            console.log(e);
            this.$snotify.error("Beim Hochladen ist ein Fehler aufgetreten.");
          });
      }
    },
    successMethod() {
      this.$router.push({
        name: "SettingsProducts"
      });
      this.$snotify.success("Das Produkt wurde erfolgreich gespeichert.");
    },
    errorMethod(e) {
      console.log(e);
      this.$snotify.error(
        "Beim Speichern des Produkts ist ein Fehler aufgetreten."
      );
    }
  }
};
</script>

<style scoped></style>
