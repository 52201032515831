<template>
  <div style="width: 100%;" class="body-1">
    <v-row justify="center" v-if="inquiry">
      <v-col cols="12" xl="9">
        <!-- name and description -->
        <inquiries-show-header />
        <!-- current state, user-details -->
        <inquiries-show-info />
        <!-- current state, user-details -->
        <inquiries-show-timeline />

        <!-- settings and share -->
        <v-speed-dial
          right
          bottom
          v-model="fab"
          direction="top"
          fixed
          v-if="$hasPermissions('consultant permission')"
        >
          <template v-slot:activator>
            <v-btn fab v-model="fab" dark color="primary">
              <v-icon v-if="!fab">mdi-buffer</v-icon>
              <v-icon v-else>mdi-close</v-icon>
            </v-btn>
          </template>
          <v-tooltip left v-if="!inquiry.policy_deleted_at">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  fab
                  small
                  exact
                  :to="{
                    name: 'InquiriesSettings',
                    params: { id: $route.params.id }
                  }"
                >
                  <v-icon>mdi-cog-outline</v-icon>
                </v-btn>
              </div>
            </template>
            <span>Einstellungen</span>
          </v-tooltip>
          <v-tooltip left v-if="!inquiry.policy_deleted_at">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                small
                v-bind="attrs"
                v-on="on"
                @click="shareDialog = true"
              >
                <v-icon>mdi-share-variant</v-icon>
              </v-btn>
            </template>
            <span>Zugriffsberechtigungen verwalten</span>
          </v-tooltip>
          <v-tooltip left v-if="inquiry.policy_deleted_at">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                small
                v-bind="attrs"
                v-on="on"
                @click="deleteDialog = true"
              >
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </template>
            <span>Anfrage entgültig löschen</span>
          </v-tooltip>
        </v-speed-dial>
      </v-col>
      <v-dialog v-model="shareDialog" max-width="600">
        <v-card>
          <inquiries-show-share type="inquiry" />
        </v-card>
      </v-dialog>

      <!--Delete dialog-->
      <v-dialog v-model="deleteDialog" persistent max-width="500px">
        <v-card>
          <v-card-title class="headline">
            Wollen Sie die Anfrage wirklich löschen?
          </v-card-title>
          <v-card-text>
            Wenn Sie bestätigen, wird die Anfrage unwiderruflich gelöscht.
          </v-card-text>
          <v-card-actions class="px-4">
            <v-spacer />
            <v-btn text @click="deleteDialog = false">
              Abbrechen
            </v-btn>
            <v-btn
              color="error"
              class="ml-2"
              @click="
                destroyInquiry();
                deleteDialog = false;
              "
              :loading="deleteLoading"
            >
              Bestätigen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {},
  data: () => ({
    fab: false,
    shareDialog: false,
    deleteDialog: false,
    deleteLoading: false
  }),
  computed: {
    inquiry() {
      return this.$store.getters["currentInquiry/currentInquiry"];
    }
  },
  watch: {},
  created() {
    this.vuexShowCurrentInquiry(this.$route.params.id).then(() => {
      let breadCrumbs = [];
      if (
        this.inquiry &&
        this.inquiry.assignable_type === "App\\Models\\Team" &&
        this.$hasPermissions("consultant permission")
      ) {
        breadCrumbs = [
          {
            text: "Anfragen: Meinen Teams zugewiesen",
            to: {
              name: "Inquiries",
              query: { type: "assignedToTeams", view: "list" }
            },
            disabled: false,
            exact: true
          },
          {
            text: "Team: " + this.inquiry.assignable.name,
            to: {
              name: "TeamsShow",
              params: { id: this.inquiry.assignable_id }
            },
            exact: true,
            disabled: false
          },
          {
            text: "Anfrage: " + this.inquiry.name,
            to: {
              name: "InquiriesShow",
              params: { id: this.$route.params.id }
            },
            exact: true,
            disabled: false
          }
        ];
      } else {
        breadCrumbs = [
          {
            text: "Anfragen",
            to: { name: "Inquiries" },
            disabled: false,
            exact: true
          },
          {
            text: "Anfrage: " + this.inquiry.name,
            to: {
              name: "InquiriesShow",
              params: { id: this.$route.params.id }
            },
            exact: true,
            disabled: false
          }
        ];
      }

      this.$store.commit("application/setBreadCrumbs", breadCrumbs);
    });
  },
  methods: {
    ...mapActions({
      vuexShowCurrentInquiry: "currentInquiry/showInquiry"
    }),
    destroyInquiry() {
      this.deleteLoading = true;
      this.axios
        .delete("/inquiries/" + this.$route.params.id)
        .then(() => {
          this.$snotify.success("Die Anfrage wurde erfolgreich gelöscht.");
          this.$router.push({ name: "Inquiries" });
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error("Die Anfrage konnte nicht gelöscht werden.");
        })
        .finally(() => {
          this.deleteLoading = false;
        });
    }
  }
};
</script>

<style scoped></style>
