<template>
  <v-row>
    <v-col cols="12" xl="6" lg="8">
      <!--      <v-form
        lazy-validation
        ref="form"
        v-model="isValid"
        @submit.prevent="submit()"
      >
        <div class="text-overline">
          Allgemein
        </div>

        <v-select
          v-model="user.type"
          label="Typ"
          :items="typeItems"
          filled
          :rules="[rules.required]"
          required
        />

        <v-select
          v-model="user.title"
          label="Titel"
          :items="titleItems"
          filled
          required
          clearable
        />

        <v-text-field
          v-model="user.first_name"
          filled
          label="Vorname"
          :rules="[rules.required, rules.short_text]"
          required
          :counter="280"
        />

        <v-text-field
          v-model="user.last_name"
          filled
          label="Nachname"
          :rules="[rules.required, rules.short_text]"
          :counter="280"
        />
        <div class="text-overline mt-4">
          Kontakt
        </div>
        <v-text-field
          v-model="user.email"
          filled
          label="E-Mail"
          :rules="[rules.required, rules.short_text, rules.email]"
          :counter="280"
        />

        <v-text-field
          v-model="user.phone1"
          filled
          label="Telefon"
          :rules="[rules.short_text, rules.phone]"
          :counter="280"
        />

        <v-text-field
          v-model="user.phone2"
          filled
          label="Mobil"
          :rules="[rules.short_text, rules.phone]"
          :counter="280"
        />

        <div class="text-overline mt-4">
          Adresse
        </div>

        <v-text-field
          v-model="user.address1"
          filled
          label="Straße und Hausnummer"
          :rules="[rules.short_text]"
          :counter="280"
        />

        <v-text-field
          v-model="user.address2"
          filled
          label="Adresszusatz"
          :rules="[rules.short_text]"
          :counter="280"
        />

        <v-row>
          <v-col cols="12" md="5">
            <v-text-field
              v-model="user.zip"
              filled
              label="PLZ"
              :rules="[rules.short_text]"
              :counter="280"
            />
          </v-col>
          <v-col cols="12" md="7">
            <v-text-field
              v-model="user.city"
              filled
              label="Ort"
              :rules="[rules.short_text]"
              :counter="280"
            />
          </v-col>
        </v-row>

        <div v-if="user.type === 'extern'">
          <div class="text-overline mt-4">
            Kundenspezifisch
          </div>
          <v-text-field
            v-model="user.label"
            filled
            label="Kundennummer"
            :rules="[rules.short_text]"
            :counter="280"
          />

          <v-autocomplete
            v-model="user.customer_type_id"
            :items="customerTypes"
            filled
            label="Kundentyp"
            hide-no-data
            clearable
            item-text="name"
            item-value="id"
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                <v-list-item-subtitle v-if="data.item.label">
                  Kundennummer: {{ data.item.label }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="data.item.customerType_name">
                  <v-chip x-small>{{ data.item.customerType_name }}</v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-dialog
                  v-model="data.item.editDialog"
                  persistent
                  max-width="700px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed v-bind="attrs" v-on="on">
                      Bearbeiten
                      <v-icon right>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-text>
                      <util-user-form
                        v-on:closeDialog="
                          data.item.editDialog = false;
                          customerEditDialogKey++;
                        "
                        v-on:patchUser="
                          data.item.editDialog = false;
                          inquiry.customer_id = $event;
                          indexUsers();
                          customerEditDialogKey++;
                        "
                        :userId="data.item.id"
                        :key="customerEditDialogKey"
                      />
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-list-item-action>
            </template>
          </v-autocomplete>

          &lt;!&ndash;          <v-select
            :items="companies"
            label="Unternehmen"
            filled
            v-model="user.company_id"
            item-text="name"
            item-value="id"
            clearable
          />&ndash;&gt;

          <div class="text-overline mt-4">
            Berechtigungen
          </div>

          <v-select
            :items="roles_extern"
            item-text="name_de"
            item-value="name"
            label="Rollen"
            multiple
            filled
            v-model="user.roles"
          />
        </div>

        <div v-if="user.type === 'intern'">
          <div class="text-overline mt-4">
            Mitarbeiterspezifisch
          </div>
          <v-text-field
            v-model="user.label"
            filled
            label="Personalnummer"
            :rules="[rules.short_text]"
            :counter="280"
          />
          <v-select
            :items="teams"
            label="Teams"
            filled
            v-model="user.teams"
            item-text="name"
            item-value="id"
            multiple
            clearable
          />

          <div class="text-overline mt-4">
            Profilbild
          </div>

          <img
            :src="user.image_url"
            alt="Profilbild"
            v-if="user.image_url"
            style="height: 100px; max-width: 300px;"
          />
          <util-file-drop :fileProp="file" @pass="file = $event" />

          <div class="text-overline mt-4">
            Berechtigungen
          </div>

          <v-select
            :items="roles_intern"
            v-model="user.roles"
            item-text="name_de"
            item-value="name"
            label="Rollen"
            multiple
            filled
          />
        </div>

        <div v-if="!user.id">
          <div class="text-overline mt-4">
            Zugangsdaten
          </div>
          <v-radio-group v-model="user.is_invited" row>
            &lt;!&ndash; TODO: Wieder möglich machen -> Mailing
             <v-radio label="Nutzer wird eingeladen." :value="true" />&ndash;&gt;
            <v-radio label="Ein Passwort wird vergeben." :value="false" />
          </v-radio-group>
          <div v-if="!user.is_invited">
            <v-text-field
              type="password"
              clearable
              filled
              :rules="[rules.required, rules.password_length]"
              v-model="user.password"
              label="Passwort"
            />

            <v-text-field
              filled
              type="password"
              clearable
              v-model="user.password_confirmation"
              :rules="[rules.required, rules.password_length]"
              label="Passwort bestätigen"
            />
          </div>
        </div>

        <div class="d-flex justify-end mt-6">
          <v-btn text :to="{ name: 'SettingsUsers' }" exact>Abbrechen</v-btn>
          <v-btn
            class="ml-2"
            color="primary"
            type="submit"
            :disabled="!isValid"
            :loading="loading"
          >
            Speichern
          </v-btn>
        </div>
      </v-form>-->
      <util-user-form type="settings" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({}),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    },
    titleItems() {
      return this.$store.getters["application/userTitles"];
    }
  },
  watch: {
    $route(to) {
      if (to.name === "SettingsDocumentTypesEdit" && to.params.id) {
        this.showUser();
        this.indexCompanies();
        this.indexCustomerTypes();
        this.indexTeams();
      }
    }
  }
};
</script>

<style scoped></style>
