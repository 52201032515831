<template>
  <div>
    <v-row align="center">
      <v-col cols="12" xl="4" lg="6">
        <v-text-field
          solo
          solo-inverted
          flat
          placeholder="Suchen"
          prepend-inner-icon="mdi-magnify"
          dense
          hide-details
          v-model="search"
        />
      </v-col>
      <v-col cols="12" xl="2" lg="3">
        <v-btn depressed block height="38px" @click="groupDialog = true">
          Neuer Ordner
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" xl="2" lg="3">
        <v-btn
          depressed
          block
          height="38px"
          :to="{ name: 'SettingsDocumentsCreate' }"
        >
          Neues Dokument
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="8" lg="12">
        <v-card>
          <v-tabs color="black" background-color="transparent">
            <v-tab>Aktiv</v-tab>
            <v-tab>Archiviert</v-tab>

            <v-tab-item>
              <v-card flat v-if="documents.length === 0">
                <v-card-text>
                  Keine aktiven Dokumente vorhanden.
                </v-card-text>
              </v-card>
              <util-documents-tree-view
                v-else
                :documents="documents"
                :search="search"
                editRoute="SettingsDocumentsEdit"
              />
            </v-tab-item>

            <v-tab-item>
              <v-card flat v-if="documents_archived.length === 0">
                <v-card-text>
                  Keine archivierten Dokumente vorhanden.
                </v-card-text>
              </v-card>
              <util-documents-tree-view
                v-else
                :documents="documents_archived"
                :search="search"
                editRoute="SettingsDocumentsEdit"
              />
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="groupDialog" max-width="500px">
      <util-group-form
        typeProp="documents"
        @dialog="
          groupDialog = false;
          groupFormKey++;
          indexDocuments();
        "
        :key="groupFormKey"
      />
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    groupDialog: false,
    groupFormKey: 1,
    documents: [],
    documents_archived: [],
    search: ""
  }),
  computed: {},
  created() {
    this.indexDocuments();
  },
  methods: {
    indexDocuments() {
      this.axios
        .get("/documents?tree=true")
        .then(res => {
          this.documents = res.data.documents;
          this.documents_archived = res.data.documents_archived;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error("Ein Fehler ist aufgetreten.");
        });
    }
  }
};
</script>

<style scoped></style>
