<template>
  <div>
    <div class="mb-10">
      <v-btn-toggle>
        <v-btn text height="38px" :to="{ name: 'InquiriesColumn' }">
          Spaltenansicht
        </v-btn>
        <v-btn text height="38px" :to="{ name: 'InquiriesList' }">
          Listenansicht
        </v-btn>
      </v-btn-toggle>
      <v-btn
        height="38px"
        depressed
        class="ml-4"
        :to="{ name: 'InquiriesForm' }"
      >
        Neu anlegen
        <v-icon right>mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-row class="mt-4">
      <v-col cols="12" md="4">
        <v-card flat color="grey lighten-3" min-height="400px">
          <v-card-title class="text-overline">
            <v-icon left>mdi-radiobox-marked</v-icon>
            Offen
            <v-spacer />
          </v-card-title>
          <v-card-text class="mt-n4">
            <draggable
              :list="assignedInquiries_open"
              group="inquiries"
              style="min-height: 200px"
              @end="updateInquiryStates"
            >
              <div
                v-for="inquiry in assignedInquiries_open"
                :key="inquiry.id"
                class="mt-4"
              >
                <util-inquiry-card :inquiryProp="inquiry" />
              </div>
            </draggable>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card flat color="grey lighten-3" min-height="400px">
          <v-card-title class="text-overline">
            <v-icon left>mdi-briefcase-outline</v-icon>
            In Arbeit
          </v-card-title>
          <v-card-text class="mt-n4" style="min-height: 100%;">
            <draggable
              :list="assignedInquiries_in_work"
              group="inquiries"
              style="min-height: 200px;"
              @end="updateInquiryStates"
            >
              <div
                v-for="inquiry in assignedInquiries_in_work"
                :key="inquiry.id"
                class="mt-4"
              >
                <util-inquiry-card :inquiryProp="inquiry" />
              </div>
            </draggable>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card flat color="grey lighten-3" min-height="400px">
          <v-card-title class="text-overline">
            <v-icon left>mdi-checkbox-marked-circle-outline</v-icon>
            Fertig
          </v-card-title>
          <v-card-text class="mt-n4" style="min-height: 100%;">
            <draggable
              :list="assignedInquiries_finished"
              group="inquiries"
              style="min-height: 200px"
              @end="updateInquiryStates"
            >
              <div
                v-for="inquiry in assignedInquiries_finished"
                :key="inquiry.id"
                class="mt-4"
              >
                <util-inquiry-card :inquiryProp="inquiry" />
              </div>
            </draggable>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable
  },
  data: () => ({
    loading: false,
    isValid: false,
    assignedInquiries_open: [],
    assignedInquiries_in_work: [],
    assignedInquiries_finished: []
  }),
  computed: {
    userDetails() {
      return this.$store.getters["state/userDetails"];
    }
  },
  created() {
    this.showDashboardInquiries();
  },
  methods: {
    showDashboardInquiries() {
      this.axios
        .get("/dashboard/inquiries?view=column")
        .then(res => {
          this.assignedInquiries_open = res.data.assignedInquiries_open;
          this.assignedInquiries_in_work = res.data.assignedInquiries_in_work;
          this.assignedInquiries_finished = res.data.assignedInquiries_finished;
        })
        .catch(err => {
          console.log(err);
          this.$snotify.error("Ein Fehler ist aufgetreten.");
        });
    },
    updateInquiryStates() {
      this.axios
        .patch("/inquiries/state", {
          open: this.assignedInquiries_open,
          in_work: this.assignedInquiries_in_work,
          finished: this.assignedInquiries_finished
        })
        .catch(e => {
          console.log(e);
        });
    }
  }
};
</script>

<style scoped></style>
