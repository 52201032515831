<template>
  <v-row>
    <v-col cols="12" xl="6" lg="8">
      <v-form
        lazy-validation
        ref="form"
        v-model="isValid"
        @submit.prevent="submit()"
      >
        <div class="text-overline">
          Allgemein
        </div>

        <v-text-field
          v-model="uploadType.name"
          filled
          label="Name"
          :rules="[rules.required, rules.short_text]"
          required
          :counter="280"
        />

        <v-textarea
          v-model="uploadType.description"
          filled
          label="Beschreibung"
          :rules="[rules.long_text]"
          :counter="6000"
        />

        <v-select
          v-model="uploadType.mime_types"
          filled
          label="Erweiterungen"
          :rules="[rules.required]"
          required
          multiple
          :items="filenameExtensions"
          clearable
          @click:clear="uploadType.mime_types = null"
        />

        <div class="d-flex justify-end mt-6">
          <v-btn text :to="{ name: 'SettingsUploadTypes' }" exact
            >Abbrechen</v-btn
          >
          <v-btn
            class="ml-2"
            color="primary"
            type="submit"
            :disabled="!isValid"
            :loading="loading"
          >
            Speichern
          </v-btn>
        </div>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    isValid: false,
    uploadType: {
      name: "",
      description: "",
      mime_types: null
    }
  }),
  computed: {
    rules() {
      return this.$store.getters["application/rules"];
    },
    filenameExtensions() {
      return this.$store.getters["application/filenameExtensions"];
    }
  },
  watch: {
    $route(to) {
      if (to.name === "SettingsUploadTypesEdit" && to.params.id) {
        this.showUploadType();
      }
    }
  },
  created() {
    if (this.$route.params.id) {
      this.showUploadType();
    }
  },
  methods: {
    submit() {
      const url = "/upload-types";
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.$route.params.id) {
          this.axios
            .patch(url + "/" + this.$route.params.id, {
              uploadType: this.uploadType
            })
            .then(() => {
              this.successMethod();
            })
            .catch(e => {
              this.errorMethod(e);
            });
        } else {
          this.axios
            .post(url, {
              uploadType: this.uploadType
            })
            .then(() => {
              this.successMethod();
            })
            .catch(e => {
              this.errorMethod(e);
            });
        }
      }
    },
    showUploadType() {
      this.axios
        .get("/upload-types/" + this.$route.params.id)
        .then(r => {
          this.uploadType = { ...r.data.uploadType };
          if (this.uploadType.mime_types.length === 0) {
            this.uploadType.mime_types = null;
          }
        })
        .catch(e => {
          console.log(e);
          this.$snotify.error(
            "Beim Abrufen des Dateiformats ist ein Fehler aufgetreten."
          );
        });
    },
    successMethod() {
      this.$router.push({
        name: "SettingsUploadTypes"
      });
      this.$snotify.success("Das Dateiformat wurde erfolgreich gespeichert.");
    },
    errorMethod(e) {
      console.log(e);
      this.$snotify.error(
        "Beim Speichern des Dateiformats ist ein Fehler aufgetreten."
      );
    }
  }
};
</script>

<style scoped></style>
